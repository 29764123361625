import React, {useEffect, useMemo, useRef} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';

import BrandListItem from '@/components/brandListItem';
import {IsOnlyBenefit, SelectedCategory, user} from '@/store';
import {ReactComponent as NewpickIcon} from '@/assets/icons/newpickIcon.svg';
import {ReactComponent as NewpickArrow} from '@/assets/icons/newpickArrow.svg';
import {ReactComponent as NPickIcon} from '@/assets/icons/nPick.svg';
import {ReactComponent as NoSearchResult} from '@/assets/icons/no-search-result-icon.svg';
import {ReactComponent as MapBtnIcon} from '@/assets/icons/map-btn-icon.svg';
import {useScroll} from '@/hooks/useScroll';

import styles from './styles.module.scss';

interface Props {
  items: BrandListItemType[];
  height: number;
  isMaxHeight: boolean;
  selectedBrand: BrandListItemType | undefined;
  isScrollLock: boolean;
  goToMap: () => void;
  scrollRef: React.MutableRefObject<HTMLInputElement | null>;
}

export default function BrandList({
  items,
  height,
  isMaxHeight,
  selectedBrand,
  isScrollLock,
  goToMap,
  scrollRef,
}: Props) {
  const navigator = useNavigate();
  const {
    syncScroll,
    resetScroll,
    checkScrolled,
    setCombinedRef,
    scrollRef: saveScrollRef,
  } = useScroll();
  const realtimeChecker = useRef<HTMLInputElement | null>(null);
  const accessToken = useRecoilValue(user);
  const [selectedCategory, setSelectedCategory] =
    useRecoilState(SelectedCategory);
  const [isOnlyBenefit, setIsOnlyBenefit] = useRecoilState(IsOnlyBenefit);
  const toggleSelectedCategory = (category: string) => {
    setSelectedCategory(category);
    scrollRef.current?.scrollTo(0, 0);
  };
  const filteredBrands = useMemo(() => {
    if (selectedBrand) {
      return items.filter(item => item.id === selectedBrand.id);
    }

    if (selectedCategory.length > 0) {
      return selectedCategory != 'All'
        ? items.filter(item => {
            const isInCategory = item.categoryList.includes(selectedCategory);
            const hasBenefit = isOnlyBenefit ? !!item.benefits : true;
            return isInCategory && hasBenefit;
          })
        : items;
    }

    return items;
  }, [selectedBrand, items, selectedCategory, isOnlyBenefit]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.style.overflow = isScrollLock ? 'hidden' : 'auto';
      saveScrollRef.current = scrollRef.current;
    }
    if (!isScrollLock) {
      if (checkScrolled(window.location.pathname)) {
        syncScroll();
      } else {
        resetScroll();
      }
    }
  }, [isScrollLock, scrollRef]);

  return (
    <div>
      {!isMaxHeight && !selectedBrand && (
        <div className={styles.tabWrapper}>
          {/* tab */}
          <button
            className={`${styles.tab} ${
              selectedCategory === 'All' ? styles.selected : ''
            }`}
            onClick={() => toggleSelectedCategory('All')}>
            전체
          </button>
          <button
            className={`${styles.tab} ${
              selectedCategory === 'Cafe' ? styles.selected : ''
            }`}
            onClick={() => toggleSelectedCategory('Cafe')}>
            카페
          </button>
          <button
            className={`${styles.tab} ${
              selectedCategory === 'Restaurant' ? styles.selected : ''
            }`}
            onClick={() => toggleSelectedCategory('Restaurant')}>
            음식점
          </button>
          <button
            className={`${styles.tab} ${
              selectedCategory === 'Bar / Pub' ? styles.selected : ''
            }`}
            onClick={() => toggleSelectedCategory('Bar / Pub')}>
            바/펍
          </button>
        </div>
      )}
      {isMaxHeight && (
        <button className={styles.newpick} onClick={() => navigator('/n-pick')}>
          <NewpickIcon />
          <span>뉴뉴픽 브랜드는 할인권 받아가세요</span>
          <NewpickArrow />
        </button>
      )}
      <div
        className={styles.brands}
        ref={el => setCombinedRef(el, scrollRef)}
        style={{overflow: 'auto', height: `${height}px`}}>
        {!selectedBrand && (
          <div className={styles.benefits} style={{cursor: 'pointer'}}>
            <label className={styles.custom_checkbox}>
              <input
                type="checkbox"
                checked={isOnlyBenefit}
                ref={realtimeChecker}
                onChange={() => {
                  if (accessToken) {
                    setIsOnlyBenefit(!isOnlyBenefit);
                  }
                }}
              />
              <span className={styles.checkmark}></span>
            </label>
            <span
              onClick={() => {
                if (realtimeChecker.current) {
                  realtimeChecker.current.click();
                }
              }}>
              <NPickIcon />
            </span>
          </div>
        )}
        {/* list */}
        {filteredBrands.length > 0 ? (
          filteredBrands.map((item, i) => {
            return (
              <BrandListItem
                key={`brand_list_item_${item.id}`}
                item={item}
                itemStyle={{
                  margin: i === 0 ? '0 0 40px 0' : '40px 0',
                }}
                isCurrentLocation={false}
                isDisabled={true}
              />
            );
          })
        ) : (
          <div className={styles.noListWrapper}>
            <div className={styles.noList}>
              <NoSearchResult />
              <div className={styles.noListMsg}>
                이 지역에는 브랜드가 없습니다.
                <br />
                지도 위치를 변경해 보세요.
              </div>
              <button
                className={styles.noListBtn}
                onClick={() => navigator('/recommend-brand')}>
                이 지역 브랜드 추천하기
              </button>
            </div>
          </div>
        )}
      </div>
      {isMaxHeight && (
        <div className={styles.keywordSelectorWrapper}>
          <div className={styles.keywordSelector}>
            <button
              className={`${styles.keyword} ${styles.keyword_left} ${
                selectedCategory === 'All' ? styles.selected : ''
              }`}
              onClick={() => toggleSelectedCategory('All')}>
              전체
            </button>
            <button
              className={`${styles.keyword} ${
                selectedCategory === 'Cafe' ? styles.selected : ''
              }`}
              onClick={() => toggleSelectedCategory('Cafe')}>
              카페
            </button>
            <button
              className={`${styles.keyword} ${
                selectedCategory === 'Restaurant' ? styles.selected : ''
              }`}
              onClick={() => toggleSelectedCategory('Restaurant')}>
              음식점
            </button>
            <button
              className={`${styles.keyword} ${styles.keyword_right} ${
                selectedCategory === 'Bar / Pub' ? styles.selected : ''
              }`}
              onClick={() => toggleSelectedCategory('Bar / Pub')}>
              바/펍
            </button>
          </div>
          <button className={styles.mapBtn} onClick={() => goToMap()}>
            <MapBtnIcon />
          </button>
        </div>
      )}
    </div>
  );
}
